.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: #8F5C2C;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    /* color: #40A4C8; */
    color:  rgb(0, 130, 206);
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a {
        color: black;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem a:active,
    .NavigationItem a.active {
        background-color: white;
        border-bottom: 1px solid rgb(0, 130, 206);
        color: rgb(0, 130, 206);
    }

    .NavigationItem a:hover {
        color: rgb(0, 130, 206);
    }
}