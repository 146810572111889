.Toolbar {
    height: 48px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
    z-index: 1000;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%);
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    height: 80%;
    display: inline-flex;
    align-items: center;
}

@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}