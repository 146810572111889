.Content {
  margin: 48px 0px 0px 48px;
  background-color: #e7e7e7;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow-y: auto;
}

.Content a {
  text-decoration: none;
  color: gray;
}

.Content a:hover {
  text-decoration: underline;
  color: gray;
}

.Pages {
  margin-left: 48px;
}

.Sidebar {
  position: fixed;
  float: left;
  align-items: flex-start;
  background-color: white;
  height: 100%;
  overflow-y: auto;
  box-shadow: 3px 0px 3px -1px rgb(0 0 0 / 20%);
  z-index: 500;
  width: 48px;
  margin-top: 48px;
  scrollbar-width: none;
}

.Sidebar::-webkit-scrollbar {
  width: 0px;
}

.SidebarHover::-webkit-scrollbar {
  width: 7px;
}

.SidebarHover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 3px;
}

.SidebarHover::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 3px;
}

.SidebarHover {
  width: 200px;
  transition: 0.1s;
  scrollbar-width: inherit;
}

.Sidebar:hover p {
  display: inline-block;
  transition: 0.1s;
}

.SidebarItem {
  display: inline-flex;
  width: 100%;
  text-decoration: none;
  color: black;
}

.SidebarItem p {
  margin-top: auto;
  margin-bottom: auto;
  box-sizing: border-box;
}

.SidebarItem:hover {
  cursor: pointer;
  background-color: lightgray;
}

.SidebarItem:hover p {
  display: inline-flex !important;
}

.Sidebar svg {
  padding: 12px;
  color: gray;
  display: block;
}

.SidebarItem:hover,
.SidebarItem:active,
.SidebarItem.active,
.SidebarItem:hover svg,
.SidebarItem.active > svg {
  color: rgb(0, 130, 206);
}

@media (max-width: 499px) {
  .Sidebar {
    display: none;
  }

  .Content {
    margin: 48px 0px 0px 0px;
  }

  .Pages {
    margin-left: 0px;
  }

  .SidebarItem {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .SidebarItem p {
    margin-left: 7px;
  }
  
}
