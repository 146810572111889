body {
    background: url(../../assets/images/login-bg.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Login {
    width: 350px;
    margin: auto;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
}

.Info {
    background-color: rgb(205, 219, 247);
    padding: 12px;
    border-radius: 7px;
}
