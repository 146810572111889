.SectionTitle {
    color: #FFCC00;
    font-size: large;
    font-weight: bold;
    margin: auto !important;
}

.Canvas {
    margin: 12px;
    padding: 0px 12px 12px 12px;
}

.FormCard {
    padding: 20px;
}

@media (max-width: 499px) {
    .Canvas {
        margin: 7px;
        padding: 0px 7px 7px 7px;
    }
} 